import 'animate.css';
import { SiteProvider } from './src/common/Context/app';
import { FormComponentProvider } from './src/common/Context/form';
import './src/common/styles/app.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const wrapRootElement = ({ element }) => (
  <SiteProvider>
    <FormComponentProvider>
      <GoogleReCaptchaProvider reCaptchaKey="6LcOx7kkAAAAAO2o8T-4mKRutgmVOp97A6ta36vc">
        {element}
      </GoogleReCaptchaProvider>
    </FormComponentProvider>
  </SiteProvider>
);

const waitForElement = (querySelector, timeout) => {
  return new Promise((resolve, reject) => {
    let timer = false;
    const observer = new MutationObserver(() => {
      const element = document.querySelector(querySelector);
      if (element) {
        observer.disconnect();
        if (timer !== false) clearTimeout(timer);
        return resolve(element);
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
    if (timeout)
      timer = setTimeout(() => {
        observer.disconnect();
        // eslint-disable-next-line prefer-promise-reject-errors
        reject?.();
      }, timeout);
  });
};

const anchorScroll = (location) => {
  // Check for location so build does not fail
  if (location?.hash) {
    waitForElement(location?.hash, 2000).then((element) => {
      element?.scrollIntoView?.({ behavior: `smooth`, block: `start` });
    });
  }
};

export const onRouteUpdate = ({ location }) => {
  anchorScroll(location);
  return true;
};
export const shouldUpdateScroll = ({
  routerProps: { location },
  // getSavedScrollPosition,
}) => {
  anchorScroll(location);
  window.scrollTo([0, 0]);
  return true;
};
